'use strict'

import api from '@/api/api';
import userService from '../../services/user.service'
import ncryptService from '../../services/ncrypt.service'

import { CONSTANTS } from "../../global/index"
import header from './header';
const { GETTING_INFO,
    GET_INFO_SUCCESS,
    GETTING_IMG,
    GET_IMG_SUCCESS,
    SET_HISTORY_OPTION,
    SET_ADDED, SET_STATUS,
    GETTING_MSG, GET_MSG,
    ERROR_MSG,
    ERROR_STATUS
} = CONSTANTS;

const ERROR_OBJ = {
    status: ERROR_STATUS,
    message: ERROR_MSG,
    result: "",
}

export default {
    namespaced: true,
    state: {
        loading: false,
        data: [],
        loading_img: false,
        img: null,
        historyOption: 'Default',
        historyIndex: 0,
        status: '',
        msg: 'Ver Todos',
        added: ''
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
        loading_img(state) {
            return state.loading_img;
        },
        img(state) {
            return state.img;
        },
        getHistoryOption(state) {
            return state.historyOption
        },
        getHistoryIndex(state) {
            return state.historyIndex
        },
        getAdded(state) {
            return state.added
        },
        status(state) {
            return state.status
        },
        msg(state) {
            return state.msg
        },

    },
    mutations: {
        [GETTING_INFO](state) {
            state.loading = true;
            state.data = [];
        },
        [GET_INFO_SUCCESS](state, change) {
            state.loading = false;
            state.data = change;
        },
        [GETTING_IMG](state) {
            state.loading_img = true;
            state.img = null;
        },
        [GET_IMG_SUCCESS](state, change) {
            state.loading_img = false;
            state.img = change;
        },
        [SET_HISTORY_OPTION](state, change) {
            state.historyOption = change;
        },
        [SET_ADDED](state, change) {
            state.added = change;
        },
        [SET_STATUS](state, value) {
            state.status = value;
        },
        [GET_MSG](state, value) {
            state.msg = value
        },
        [GETTING_MSG](state) {
            state.msg = 'Cargando...'
        },
    },
    actions: {
        setAddedRvw({ commit }, payload) {
            commit(SET_ADDED, payload);
        },

        setHistoryOptionRvw({ commit }, payload) {
            commit(SET_HISTORY_OPTION, payload);
        },
        async getAllInfoRvw({ commit }, payload) {
            commit(GETTING_INFO);
            commit(GETTING_MSG);

            const normalizedOpt = payload.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f-\s]/g, "");


            try {

                const token = userService.getToken()
                const _pk = ncryptService._createPubToken()



                const headers = { 'Authorization': token, _pk };

                const response = await api.getAllInfo(normalizedOpt, headers);

                if (response.data.status === 'success') {
                    commit(GET_INFO_SUCCESS, response.data.result);
                } else {
                    commit(GET_INFO_SUCCESS, response.data.result);
                }
                commit(GET_MSG, "Ver todos");


                return response.data;
            } catch (error) {
                return ERROR_OBJ;
            }

        },

        async getInfoByIdRvw({ commit }, payload) {
            commit(GETTING_INFO);
            try {
                const token = userService.getToken()
                const _pk = ncryptService._createPubToken()
                const headers = { 'Authorization': token, _pk };

                const response = await api.getInfoById(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                return response.data.result;
            } catch (error) {
                return ERROR_OBJ;
            }

        },


        async deleteItemRvw({ commit }, payload) {
            commit(SET_STATUS, 'SUCCESS')
            try {
                const token = userService.getToken()
                const _pk = ncryptService._createPubToken()
                const headers = { 'Authorization': token, _pk };
                const response = await api.deleteItem(payload, headers);
                return response.data;
            } catch (error) {
                return ERROR_OBJ
            }

        },
        async editItemRvw({ commit }, payload) {
            commit(SET_STATUS, 'SUCCESS')
            try {
                const token = userService.getToken()
                const _pk = ncryptService._createPubToken()
                const headers = { 'Authorization': token, _pk };
                const response = await api.editItem(payload, headers);
                return response.data;
            } catch (error) {
                return ERROR_OBJ;
            }

        },

        async addItemRvw({ commit }, payload) {
            commit(SET_STATUS, 'SUCCESS')

            try {
                const token = userService.getToken()
                const _pk = ncryptService._createPubToken()
                const headers = { 'Authorization': token, _pk };
                const response = await api.addItem(payload, headers);
                return response.data;
            } catch (error) {
                return ERROR_OBJ
            }

        },






    },
}