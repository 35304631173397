import Vue from 'vue'
import VueRouter from 'vue-router'
import userService from '../services/user.service'
Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/aviso-de-privacidad',
    name: 'Aviso de privacidad',
    component: () => import('../views/AvisoPrivacidad'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/terminos-y-condiciones',
    name: 'Términos y condiciones',
    component: () => import('../views/TerminosCondiciones'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },

  {
    path: '/iniciarsesion',
    name: 'iniciarsesion',
    component: () => import('../views/MiCuenta/UserLoginRegister'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },

  {
    path: '/auth',
    name: 'autentificacion',
    component: () => import('../views/Autentificacion'),
    meta: {
      log: true,
      admin: true,

      auth: false
    }
  },

  {
    path: '/administrador',
    name: 'Dashboard',
    component: () => import('../views/Dashboard'),
    meta: {
      log: true,
      admin: true,
      auth: true,
    }
  },

  {
    path: '/cursos-y-diplomados',
    name: 'Cursos y Diplomados',
    component: () => import('../views/CursosDiplomados'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },

  {
    path: '/Foro',
    name: 'Foro Conekta inicio',
    component: () => import('../views/Foro/ForoInicio'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/foro/cursos',
    name: 'Foro Conekta Cursos',
    component: () => import('../views/Foro/ForoCursos'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/foro/diplomados',
    name: 'Foro Conekta Diplomados',
    component: () => import('../views/Foro/ForoDiplomados'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/foro/general',
    name: 'Foro Conekta General',
    component: () => import('../views/Foro/ForoGeneral'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/foro/cursos/entrada',
    name: 'Foro Conekta Cursos Entrada',
    component: () => import('../views/Foro/ForoCursosEntrada'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/foro/diplomados/entrada',
    name: 'Foro Conekta Diplomados Entrada',
    component: () => import('../views/Foro/ForoDiplomadosEntrada'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/foro/general/entrada',
    name: 'Foro Conekta General Entrada',
    component: () => import('../views/Foro/ForoGeneralEntrada'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/biblioteca-virtual',
    name: 'Biblioteca Virtual',
    component: () => import('../views/BibliotecaVirtual'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/biblioteca-virtual/catalogo',
    name: 'Biblioteca Virtual Catalogo',
    component: () => import('../views/BibliotecaVirtualCatalogo'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/mi-cuenta',
    name: 'Mi cuenta',
    component: () => import('../views/MiCuenta/Perfil'),
    meta: {
      log: true,
      admin: false,
      auth: false,
    }
  },

  {
    path: '/carrito',
    name: 'Mi carrito',
    component: () => import('../views/Carrito/miCarrito'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/detallesdecompra',
    name: 'Detalles de Compra',
    component: () => import('../views/Carrito/DetallesDeCompra'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/detallesdesuscripcion',
    name: 'Detalles de suscripción',
    component: () => import('../views/Carrito/DetallesDeSuscripcion'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/detallesdepago',
    name: 'Detalles de Pago',
    component: () => import('../views/Carrito/DetallesDePagoFinal'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },

  {
    path: '/cursos-y-diplomados/:curso',
    name: 'cursoX',
    component: () => import('../views/CursoVista'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/biblioteca-virtual/catalogo/:libro',
    name: 'libroX',
    component: () => import('../views/LibroVista'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },

  {
    path: '*',
    name: '404',
    component: () => import('../views/Error404'),
    meta: {
      log: false,
      admin: false,

      auth: false
    }
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach(async (to, from, next) => {

  let meta_admin = to.matched.some(record => record.meta.admin);

  let meta_log = to.matched.some(record => record.meta.log);
  let meta_auth = to.matched.some(record => record.meta.auth);

  let tokenexp = await userService.tokenexp()
  let check = false //comprobar si esta logeado
  let admin = false;

  let type = ''


  if (tokenexp) { // ver que el token no este expirado.
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath') //limpiar el localstorage
    next()
  }


  let response = await userService.getData()

  if (response.status == 'error') {
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath')
  }


  if (response.status == 'success') { //comprobar que sea un login valido.
    check = true;
    let isAdmin = userService.isAdmin(response.result);
    admin = isAdmin.admin;
    type = isAdmin.type;
  }

  let ath = await userService.getAth();


  // borrar el localstorage cada que vayamos a login.

  if ((to.path === '/login' ||  to.path === '/iniciarsesion' ) && check) {
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath')
    next()
  }

  if(to.path !=='/detallesdepago'){
    localStorage.removeItem('payment')
  }
  
  //redireccionamientos por rol.

  if (!meta_log) next();

  if (meta_log && !check) next('/iniciarsesion');

  if (meta_admin) {
    if (admin) {

      if (meta_auth) {
        if (ath) {
          next();
        } else {
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      } else { //...proximamente rutas que no requieran autentificacion
        next()
      }

    } else {
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      next('/')
    }
  } else {
    next()
  }


});




export default router

