<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      colores: "",
    };
  },

  async created() {
    await this.getIdentity();
    await this.getCart()
    await this.getAllInfoClr("profile");

    this.colores = this.profiles;
    this.actualizarColores();

    let _ctk = localStorage.getItem("_ctk");
    if (!_ctk) {
      this.setCart({
        option: "set_cart",
        item: { id_cliente: null },
      });
    }


  },
  watch: {
    profiles() {
      this.actualizarColores();
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions("carrito", ["getCart"]),
    ...mapActions("colors", ["getAllInfoClr"]),
    ...mapActions("profile", ["getAllInfoPrf"]),
    ...mapActions("carrito", ["setCart"]),
    getIdentity: async function () {
      let response = await this.$store.dispatch("admin/getData");
      return response;
    },
    actualizarColores() {
      const arr = this.$store.getters["colors/data"];

      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {
          if (i == 0) {
            document.documentElement.style.setProperty(
              "--color-1",
              arr[i].code
            );
          } else if (i == 1) {
            document.documentElement.style.setProperty(
              "--color-2",
              arr[i].code
            );
          } else if (i == 2) {
            document.documentElement.style.setProperty(
              "--color-3",
              arr[i].code
            );
          } else if (i == 3) {
            document.documentElement.style.setProperty(
              "--color-4",
              arr[i].code
            );
          } else if (i == 4) {
            document.documentElement.style.setProperty(
              "--color-5",
              arr[i].code
            );
          } else if (i == 5) {
            document.documentElement.style.setProperty(
              "--color-6",
              arr[i].code
            );
          } else if (i == 6) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          }
        }
      }
    },
  },
};
</script>

<style>
@import "./css/default.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');


@font-face {
  font-family: "Gramatika-Black";
  src: url("./assets/fonts/Gramatika-Black.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Gramatika-Bold";
  src: url("./assets/fonts/Gramatika-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Gramatika-Light";
  src: url("./assets/fonts/Gramatika-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Gramatika-Medium";
  src: url("./assets/fonts/Gramatika-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "inter";
  src: url("./assets/fonts/Inter-VariableFont_slnt\,wght.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Futura Hv BT";
  src: url("./assets/fonts/FutuHv.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Futura Md BT";
  src: url("./assets/fonts/FuturaMediumBT.ttf");
  font-display: swap;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #0055b8;
  --color-2: #ffc844;
  --color-3: #ffffff;
  --color-4: #f5f5f5;
  --color-5: #a7a7a7;
  --color-6: #d9d9d9;
  --color-7: #fff;
  --color-8: #92c0e9;
  --color-9: #3c3c3c;
  --color-10: #d4f0ff;
  --color-11: #edd8ff;
  --color-12: #fffad0;
  --color-13: #d9ffcc;
  --color-14: #f93549;
  --color-15: #b5ff9b;
  --color-16: #6cb6e0;
  --color-17: #fff496;
  --color-18: #1E3D85;
  --color-19: #4CC8D9;
  --color-20: #FFAA47;


  --opacity: rgba(255, 255, 255, 0.6);


}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
}

body {
  margin: 0vw;
}

.dflxc {
  display: flex;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

.dflx {
  display: flex;
}

.dflxa {
  display: flex;
  align-items: center;
}

.dflxca {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dflxsp {
  display: flex;
  justify-content: space-between;
}

.porel {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}

.box_ctr {
  display: flex;
  justify-content: center;
}

.box_ctr_alg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crs_ptr {
  cursor: pointer;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
  background-color: #F4F4F4;
  /** border-radius: 1.0416666666666665VW;  */
}

body::-webkit-scrollbar-button:increment,
body::-webkit-scrollbar-button {
  display: none;
}

body::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

body::-webkit-scrollbar-thumb {
  background-color: #07264a;
  /** border-radius: 1.0416666666666665VW;  */
  border: 0.10416666666666667vw solid #07264a;
}

body::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.carg_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .7vw;


}

.carg_btns p:first-child {
  margin-right: 1vw;
}

.carg_btns p {
  background-color: #24397E;
  border-radius: 1.5vw;
  height: 2vw;
  margin: 0vw;
  letter-spacing: 3px;
  font-family: var(--DMSans);
  font-size: .7vw;
  width: 6vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carg_btns p img {
  width: 2.5vw;
}

.cargando_pl {
  width: 14vw !important;
  color: white !important;
}

.div_status {
  display: flex;
  align-items: center;
}

.div_status p {
  margin: 0vw;
  background-color: #d9d9d9;
  color: white;
  height: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .5vw;
  width: 7.7vw;
  cursor: pointer;
  transition: all 300ms;
}

.div_status p:hover {
  background-color: #24397E;
}

.div_status p.ds_act {
  background-color: #24397E;
  color: white;
}

.list_group {
  display: flex;
  margin-bottom: 1vw;
}

.list_cont {
  width: 16vw;
  margin-right: 2.5vw;
  position: relative;
}

.lc_item_sel {
  width: 14.167vw;
  display: flex;
  border: 1px solid #DBDBDB;
  padding: 0.417vw 0.573vw 0.469vw 0.677vw;
  cursor: pointer;
  margin-bottom: 0.156vw;
}

.lc_item_sel p {
  width: 13.542vw;
  margin: 0vw;
  color: #8D8D8D;
  font-family: "inter";
  font-size: 0.721vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 0.677vw;
}

.lc_item_sel img {
  width: 0.625vw;
}

.list_body {
  background-color: #fff;
  height: 5vw;
  overflow: auto;
  position: absolute;
  width: 16vw;
}

.list_body::-webkit-scrollbar {
  -webkit-appearance: none;
}

.list_body::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
  background-color: #F4F4F4;
  /** border-radius: 1.0416666666666665VW;  */
}

.list_body::-webkit-scrollbar-button:increment,
.list_body::-webkit-scrollbar-button {
  display: none;
}

.list_body::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

.list_body::-webkit-scrollbar-thumb {
  background-color: #07264a;
  /** border-radius: 1.0416666666666665VW;  */
  border: 0.10416666666666667vw solid #07264a;
}

.list_body::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.lb_row {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 14.167vw;
  /*border-bottom: 1px solid #DBDBDB;*/
  padding: 0.417vw 0.573vw 0.469vw 0.677vw;
  transition: all 300ms;
}

.lb_row:hover {
  background-color: var(--color-1);
}

.lb_row:hover p {
  color: white;
}

.lb_row p {
  display: flex;
  align-items: center;
  margin: 0vw;
  width: 13.542vw;
  color: #8D8D8D;
  font-family: "inter";
  font-size: 0.721vw;
  font-style: normal;
  line-height: normal;
}

p.itlc {
  font-weight: 500;
}

.csp {
  cursor: pointer !important;
}

.g_sec {
  border-bottom: .3vw solid var(--color-1);
  margin-top: 2vw;
  margin-bottom: 1vw;
}

.g_sec p {
  display: flex;
  align-items: center;
  margin: 0vw;
  color: var(--color-2);
  font-family: "inter";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
}

.navigator_sec {
  width: 97%;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 2vw;
}

.navigator_sec a {
  margin: 0vw;
  background-color: var(--color-2);
  color: white;
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.1vw;
  width: 10vw;
  cursor: pointer;
  transition: all 300ms;
  text-decoration: none;
}

.btns_prt {
  display: flex;
  align-items: center;
  margin-bottom: 1vw;
}

.btn_trash {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.btn_trash label {
  color: #E42828 !important;
  border-bottom: 2px solid #E42828;
}

.btn_refresh label {
  color: #FFAA47 !important;
  border-bottom: 2px solid #ffc844;
}

.btn_refresh label,
.btn_trash label {
  cursor: pointer;
  margin: 0vw;
  font-family: 'Inter';
  font-size: .8vw;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  letter-spacing: 0.6px;

  margin-right: 0.156vw;
}

.btn_refresh img,
.btn_trash img {
  width: 1vw;
}

.btn_refresh {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1vw;
}

.portada_ img {
  height: 10.417vw;
}

p.cnd {
  color: #8D8D8D;
  font-family: "inter";
  font-size: 0.721vw;
  font-style: normal;
  line-height: normal;
  margin: 0vw;
  margin-bottom: .5vw;
}

.add_session {
  margin-bottom: 1vw;
}

.mrgmd {
  margin-top: .5vw;
}

.mrg0 {
  margin-top: 0vw !important;
}

.subttl {
  color: #8D8D8D;
  font-family: "inter";
  font-size: 1vw;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  margin: 0vw;
  margin-bottom: .5vw;
  letter-spacing: 1px;
  border-bottom: 2px solid #ccc;
  padding-bottom: .2vw;
  width: 50%;
  padding-left: .5vw;
  margin-top: 2vw;
}

.row_session {
  display: flex;
  align-items: center;
  margin-bottom: .5vw;
  border: 1px solid #8D8D8D;
  padding: 1%;
  width: 90%;
}

.row_session p {
  color: #ffaa47;
  font-family: "inter";
  font-size: .8vw;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0vw;
  width: 90%;
}

.btn_edit,
.btn_del {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_edit img,
.btn_del img {
  cursor: pointer;
  width: .9vw;
  height: .9vw;
}

.list_sessions {
  width: 48%;
  margin-right: 2%;
  border-right: 1px solid #ccc;
}

.add_session {
  width: 48%;
  margin-right: 2%;
}

.mrglf {
  margin-left: 1vw;
}

.subt_ {

  margin: 0vw;
  font-family: 'Inter';
  font-size: .7vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
  margin-bottom: .5vw;
  color: #0055b8;
  border-bottom: 2px solid #0055b8;
  width: fit-content;
  margin-left: 1vw;
}

.row_lesson {
  display: flex;
  align-items: center;
  margin-bottom: .5vw;
  padding: 1%;
  width: 90%;
  transition: all 300ms;

}

.row_lesson:hover {
  display: flex;
  align-items: center;
  margin-bottom: .5vw;
  padding: 1%;
  width: 90%;
  background-color: #ccc;
}

.row_lesson p {
  color: #3c3c3c;
  font-family: "inter";
  font-size: .7vw;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0vw;
  width: 90%;
}

.list_lessons {
  height: 15vw;
  overflow: auto;
}


.list_lessons::-webkit-scrollbar {
  -webkit-appearance: none;
}

.list_lessons::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
  background-color: #F4F4F4;
  /** border-radius: 1.0416666666666665VW;  */
}

.list_lessons::-webkit-scrollbar-button:increment,
.list_lessons::-webkit-scrollbar-button {
  display: none;
}

.list_lessons::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

.list_lessons::-webkit-scrollbar-thumb {
  background-color: #07264a;
  /** border-radius: 1.0416666666666665VW;  */
  border: 0.10416666666666667vw solid #07264a;
}

.list_lessons::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.box_value {
  display: flex;
  align-items: center;
  border: 0.052083vw solid #ccc;
  width: 16vw;
}

.box_value p {
  font-size: 1vw;
  margin: 0vw;
  color: #242424;
  font-family: var(--Glory);
  padding-right: .2vw;
  padding-left: .3vw;
  border-right: 1px solid #ccc;
}

.box_value input {
  border: none !important;
  width: 13.5vw !important;
  padding-left: .2vw !important;
}

p.descript {
  margin: 0vw;
  color: #828282;
  font-size: .8vw;
  font-family: var(--Glory);
  margin-bottom: .1vw;
  width: 20vw;
}

.msg_cart {
  width: 90%;
  padding: 5% !important;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 700;

}

p.msg_cart_success {
  width: 100%;
  color: #828282;
  font-size: .7vw;
  font-family: var(--Glory);
  color: #07264a;


}

.iframe-pdf {
  width: 100%;
  height: 50vw;
}

p.msg_cart_error {
  width: 100%;
  color: #828282;
  font-size: .7vw;
  font-family: var(--Glory);
  color: #FFAA47;
}

p.noItemsCart {
  color: white;
  margin-left: 1.3vw;
  margin-top: -0.2vw;
  background: #ffaa47;
  position: absolute;
  width: 1.5vw;
  height: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1vw;
  font-size: .8vw;
  transform: translateX(-0.7vw) translateY(-0.6vw) scale(0.6);
}

.swiper {
  width: 100% !important;
}

/* .swiper__slide {
  width: 400px !important;
} */

@media (max-width: 768px) {
  .ql-container .ql-editor {
    font-size: 3.7vw !important;
  }



}

p.msg_error_default {
  margin-top: 1vw;
  margin-bottom: 0vw;
  color: #FF4747;
  text-decoration: none;
  font-size: 0.8333333333333334VW;
}

.cp_elemente {
  cursor: pointer !important;
}

@media (max-width: 768px) {

  p.msg_error_default {
    margin-top: 6vw;
    margin-bottom: 0vw;
    font-size: 3.864734299516908VW;
  }



  p.msg_cart_success {
    font-size: 2.5vw;

  }

  p.msg_cart_error {
    font-size: 2.5vw;

  }


}
</style>
