import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import colors from './modules/colors'
import copy from './modules/copy'
import header from './modules/header'
import logo from './modules/logo'
import main from './modules/main'
import mainmtr from './modules/mainmtr'
import profile from './modules/profile'
import usuarios from './modules/usuarios'
import category from './modules/category'
import lesson from './modules/lesson'
import course from './modules/course'
import client from './modules/client'
import suscripciones from './modules/suscripciones'
import carrito from './modules/carrito'
import coupon from './modules/coupon'
import payment from './modules/payment'
import user from './modules/user'
import mercadopago from './modules/mercadopago'
import pasarela from './modules/pasarela'
import library from './modules/library'
import author from './modules/author'
import favorito from './modules/favorito'
import relacionados from './modules/relacionados'
import download from './modules/download'
import plan from './modules/plan'
import answer from './modules/answer'
import question from './modules/question'
import forum from './modules/forum'
import plansuscriptions from './modules/plansuscriptions'
import testimonios from './modules/testimonios'
import reviews from './modules/reviews'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    colors:colors,
    copy:copy,
    header:header,
    logo:logo,
    main: main,
    mainmtr:mainmtr,
    profile:profile,
    usuarios: usuarios,
    category:category,
    lesson:lesson,
    course:course,
    client:client,
    suscripciones:suscripciones,
    carrito:carrito,
    coupon: coupon,
    payment:payment,
    user:user,
    mercadopago: mercadopago,
    pasarela: pasarela,
    library:library,
    author:author,
    favorito:favorito,
    relacionados:relacionados,
    download:download,
    plan: plan,
    answer: answer,
    question:question,
    forum:forum,
    plansuscriptions: plansuscriptions,
    testimonios,
    reviews,
  }
})
