"use strict";



const local = 'http://localhost:3344'
const cpanel = 'https://conekta.club'

exports.url = cpanel + '/api/Ncl_q3O/';


exports.normalize = (function () {
    var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
        to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};

    for (var i = 0, j = from.length; i < j; i++)
        mapping[from.charAt(i)] = to.charAt(i);

    return function (str) {
        var ret = [];
        for (var i = 0, j = str.length; i < j; i++) {
            var c = str.charAt(i);
            if (mapping.hasOwnProperty(str.charAt(i)))
                ret.push(mapping[c]);
            else
                ret.push(c);
        }
        return ret.join('').replace(/[^-A-Za-z0-9]+/g, '-');
    }

})()

const CONSTANTS = {
    GETTING_INFO: "GET_INFO",
    GET_INFO_SUCCESS: "GET_INFO_SUCCESS",
    GETTING_IMG: "GET_IMG",
    GET_IMG_SUCCESS: "GET_IMG_SUCCESS",
    SET_HISTORY_OPTION: "SET_HISTORY_OPTION",
    SET_ADDED: "SET_ADDED",
    SET_STATUS: "SET_STATUS",
    GETTING_MSG: "GETTING_MSG",
    GET_MSG: "GET_MSG",
    ERROR_MSG: "Ha ocurrido un error al intentar realizar la solicitud",
    ERROR_STATUS: "error",
    SUCCESS_STATUS: "success",
}
exports.CONSTANTS = CONSTANTS;